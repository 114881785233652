import Home from '../pages/Home'
import React from 'react'
import { Router, Routes } from 'react-router-dom'
import signup from '../pages/signup';
import signin from '../pages/signin';

const AllRoutes = () => {
  return (
    <div>
        <Routes>
            <Router path="/"  element={<home/>} />
            <Router path="/singup" element={<signup/>} />
            <Router path="/singin" element={<signin/>} />
        </Routes>
        
    </div>
  )
}

export default AllRoutes